<!--  -->
<template>
  <div class='solve-page'>
    <h3>线上答疑</h3>
    <div class="solve-content">
      <div id="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="title"><span>提问：</span>{{item.content}}</div>
          <div class="remark">
            <span>答复：</span>
            <div>{{ item.admin_id == 0 ? '尚未回复' : item.answers }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-button type="primary" round @click="visible = true">提问</el-button>

    <el-dialog
      title="线上提问"
      @close="visible = false;formData.remark = ''"
      :visible.sync="visible">
      <div class="comment-view">
        <el-form ref="form" :model="formData" label-width="90px" :rules="rules">
          <!-- <el-form-item label="满意度：" prop="grade">
            <el-select v-model="formData.grade">
              <el-option label="非常满意" value="1"></el-option>
              <el-option label="基本满意" value="2"></el-option>
              <el-option label="一般满意" value="3"></el-option>
              <el-option label="不太满意" value="4"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="标题：" prop="grade">
            <el-input v-model="formData.grade"></el-input>
          </el-form-item> -->
          <el-form-item label="问题：" prop="remark">
            <el-input type="textarea" :rows="4" v-model="formData.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="handleSubmit">提交问题</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../api/study'

export default {
  components: {},
  data() {
    return {
      list: [],
      page: 1,
      finish: false,
      visible: false,
      formData: {
        id: '',
        grade: '',
        remark: ''
      },
      rules: {
        grade: [
          { required: true, message: '请选择满意度', trigger: 'change' },
        ],
        remark: [
          { required: true, message: '请输入评价', trigger: 'blur' },
        ],
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.formData.id = this.$route.query.id
    this.getList()
  },
  mounted() {
    document.getElementById('list').addEventListener("scroll", () => {
      let scrollTop = document.getElementById('list').scrollTop
      let clientHeight = document.getElementById('list').clientHeight
      let scrollHeight = document.getElementById('list').scrollHeight
      if (scrollTop + clientHeight == scrollHeight && !this.finish) {
        this.page++
        this.getList()
      }
    })
  },
  //方法集合
  methods: {
    getList () { 
      Api.getSolveList({
        vid: this.formData.id,
        page: this.page,
        page_size: 10
      }).then(res => {
        if (res.data.code == 1) {
          this.list = this.list.concat(res.data.data.list)
          if (this.list.length == res.data.data.cnt) {
            this.finish = true
          }
          this.$nextTick(() => {
            let scrollHeight = document.getElementById('list').scrollHeight
            if (scrollHeight <= document.getElementById('list').clientHeight && !this.finish) {
              this.page++
              this.getList()
            }
          })
        } else {
          this.$message.error('列表获取失败');
        }
      })
    },
    handleSolve () {

    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          Api.solveFun({
            vid: this.formData.id,
            content: this.formData.remark,
          }).then(res => {
            if (res.data.code == 1) {
              this.visible = false
              this.page = 1
              this.finish = false
              this.list = []
              this.getList()
              this.$message.success('提交成功');
            } else {
              this.$message.error('操作失败');
            }
          })
        } else {
          return false;
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.solve-page {
  width: 100%;
  max-height: 700px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  .solve-content {
    width: 100%;
    flex: 1;
    padding: 10px;
    overflow: hidden;
    // background-color: #f0f0f0;
    > div {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .item {
        width: 99%;
        padding: 10px;
        margin-bottom: 15px;
        background-color: rgba(237, 246, 255, 0.63);
        border-radius: 3px;
        > div {
          max-width: 80%;
          span {
            font-size: inherit;
            color: #09B38A;
          }
        }
        .title {
          font-weight: bold;
          color: #555;
        }
        .remark {
          margin-top: 10px;
          display: flex;
          div {
            flex: 1;
          }
        }
      }
    }
  }
}

/deep/ .el-dialog {
  height: fit-content;
}
.comment-view {
  width: 80%;
  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>